import React from 'react';
import styled from 'styled-components';

import SEO from '../components/SEO';

const Terms: React.FC = () => {
  return (
    <>
      <SEO title={`Terms & Conditions`} />
      <Container>
        <Section>
          <Title>Legal Notice and Terms of Use</Title>
          <P>
            Welcome to the ManaMed website (the "Site"), owned and operated by
            ManaMed Inc. (hereinafter collectively referred to as "ManaMed").{' '}
          </P>
          <P>
            Please review the following legal notices and terms and conditions
            of use (collectively, the "Terms") and note that your access to
            and/or use of our website constitutes your consent to, and agreement
            to be legally bound by, these terms and conditions.
          </P>
          <P>
            There may be additional terms and conditions stated elsewhere in
            this website that apply to your use of particular functions and
            features.
          </P>
          <P>
            ManaMed may modify these Terms at any time, and those modified Terms
            will become effective immediately after ManaMed posts them on the
            Site. You agree to review the Terms posted on the Site each time you
            access or use the Site so that you are aware of any modifications
            made to these Terms.
          </P>
          <P>
            If you do not agree to these Terms, you are not authorized to access
            or use the Site. Your failure to comply with these Terms will
            constitute breach of contract and may violate ManaMed's copyright,
            trademark, and other proprietary and intellectual property rights.
          </P>
        </Section>
        <Section>
          <Title>How You May Use The Content On This Website</Title>
          <P>
            All text, images, graphics, photographs, video clips, designs,
            icons, wallpaper, characters, artwork, sounds, information,
            software, data, and other materials, and all HTML design, layouts,
            configurations, CGI, and other code and scripts in any format used
            to implement the Site (the "Content") on the Site are copyrights,
            trademarks, service marks or other intellectual property or
            proprietary rights owned or licensed by ManaMed or its affiliates,
            unless we have stated otherwise on this website.
          </P>
          <P>
            You may not distribute, publish, transmit, modify, create derivative
            works from, or in any way exploit, any of the Content, in whole or
            in part, for any purpose.
          </P>
          <P>
            Additionally, framing pages or parts of pages on our website and
            deep linking to pages in our website are prohibited. Except as
            authorized in this paragraph, you are not being granted a license
            under any copyright, trademark, service mark, patent or other
            intellectual property or proprietary right in the Content.
          </P>
          <P>
            In particular, the marks "ManaMed," "PlasmaFlow,""ManaFlexx,"
            "PlasmaFlow Elite,""Kahuna," and the "ManaMed Logo" are the
            exclusive property of the ManaMedInc.
          </P>
          <P>
            You may use the Content only for limited, internal, noncommercial,
            informational purposes. You may print out a single copy of the
            Content solely for those purposes, if the copy bears all copyright,
            trademark, service mark and other intellectual property and
            proprietary notices displayed on the web page.
          </P>
          <P>
            Under no circumstances will you obtain any rights in the products,
            services, processes or technology described in the Content. All of
            those rights are retained by ManaMed, its affiliates and any
            third-party owners of those rights.
          </P>
          <P>
            ManaMed may modify these Terms at any time, and those modified Terms
            will become effective immediately after ManaMed posts them on the
            Site. You agree to review the Terms posted on the Site each time you
            access or use the Site so that you are aware of any modifications
            made to these Terms.
          </P>
          <P>
            If you do not agree to these Terms, you are not authorized to access
            or use the Site. Your failure to comply with these Terms will
            constitute breach of contract and may violate ManaMed's copyright,
            trademark, and other proprietary and intellectual property rights.
          </P>
        </Section>
      </Container>
    </>
  );
};

export default Terms;

const Container = styled.div`
  min-height: 80vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem;
`;

const Section = styled.section`
  margin-bottom: 1rem;
  max-width: 900px;
`;

const P = styled.p`
  margin: 0;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  margin-bottom: 1rem;
  text-align: center;
`;
